exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-booking-js": () => import("./../../../src/pages/booking.js" /* webpackChunkName: "component---src-pages-booking-js" */),
  "component---src-pages-bookkeeping-mississauga-js": () => import("./../../../src/pages/bookkeeping-mississauga.js" /* webpackChunkName: "component---src-pages-bookkeeping-mississauga-js" */),
  "component---src-pages-bookkeeping-services-js": () => import("./../../../src/pages/bookkeeping-services.js" /* webpackChunkName: "component---src-pages-bookkeeping-services-js" */),
  "component---src-pages-bookkeeping-services-toronto-js": () => import("./../../../src/pages/bookkeeping-services-toronto.js" /* webpackChunkName: "component---src-pages-bookkeeping-services-toronto-js" */),
  "component---src-pages-bookkeeping-services-vancouver-js": () => import("./../../../src/pages/bookkeeping-services-vancouver.js" /* webpackChunkName: "component---src-pages-bookkeeping-services-vancouver-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-freelance-bookkeeping-toronto-js": () => import("./../../../src/pages/freelance-bookkeeping-toronto.js" /* webpackChunkName: "component---src-pages-freelance-bookkeeping-toronto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-outsource-bookkeeping-js": () => import("./../../../src/pages/outsource-bookkeeping.js" /* webpackChunkName: "component---src-pages-outsource-bookkeeping-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-thank-you-subscription-js": () => import("./../../../src/pages/thank-you-subscription.js" /* webpackChunkName: "component---src-pages-thank-you-subscription-js" */),
  "component---src-pages-white-label-bookkeeping-js": () => import("./../../../src/pages/white-label-bookkeeping.js" /* webpackChunkName: "component---src-pages-white-label-bookkeeping-js" */)
}

