import "./src/styles/index.css";
import React from "react";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LeIjWcjAAAAAE-b-I6nXBwI_VywpYvhC1UILuv-">
      {element}
    </GoogleReCaptchaProvider>
  );
};
